












































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import VWidget from '@/components/VWidget.vue';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import Activos from './activos.vue';
import Pasivos from './activos.vue';
import Ganancias from './ganancias.vue';
import Indicadores from './indicadores.vue';
import FormBase from '@/components/helper/form-base.vue';

class PageFinancialStatementRequest {
    year: any = parseInt(Util.abp.clock.today().substr(0, 4));
    customer: number = null;
}

@Component({
    components: { VWidget, Activos, Pasivos, Ganancias, Indicadores, FormBase },
})

export default class FinancialStatementForm extends ListBase {
    pagerequest: PageFinancialStatementRequest = new PageFinancialStatementRequest();
    tabModel: any = null;
    filterForm: boolean = true;

    filterItems = [
        this.gItem({ type: 'object', prop: 'year', label: 'Periodo', size: 2, hideDetails: true, options: 'years', row: true, onchange: this.loadData }),
        this.gItem({ type: 'object', prop: 'customer', label: 'Buscar Cliente por Nombre, RUC, DNI, etc.', itemText: 'description', autocomplete: true, size: 4, returnObject: false, hideDetails: true, onchange: this.loadData }),
    ]
    
    /***** computed methods *****/
    get loading() {
        return this.$store.state.financialstatement.loading;
    }

    get list() {
        return this.$store.state.financialstatement.list;
    }

    get periods() {
        return this.gCommonList('years');
    }

    get typeActivo() {
        return Util.abp.list.get('T_STATEMENT','A').id;
    }

    get typePasivo() {
        return Util.abp.list.get('T_STATEMENT', 'P').id;
    }

    get typeGanacia() {
        return Util.abp.list.get('T_STATEMENT', 'G').id;
    }

    get months() {
        let list = this.gCommonList('months');
        list.forEach(m => {
            m['field'] = 'month_' + m.id;
        });
        return list;
    }
   
    /***** fields events *****/        
    onFocus(e) {
        e.target.select();
    }
                 
    async save() {
        let type = 0;
        if (this.tabModel == 0)
            type = this.typeActivo;
        else if (this.tabModel == 1)
            type = this.typePasivo;
        else if (this.tabModel == 2)
            type = this.typeGanacia;

        let list = this.$store.state.financialstatement.list.filter(item => { return item.type_id == type });;
            
        await this.$store.dispatch({
            type: 'financialstatement/updateBatch',
            data: {
                customer_id: this.pagerequest.customer,
                year: this.pagerequest.year,
                list: list
            }
        });
    }

    async exportData() {
        let filename = await this.$store.dispatch({
            type: 'financialstatement/export',
            data: this.pagerequest
        });
        if (filename != '' && filename != undefined)
            window.location = filename;
    }

    async loadData() {
        await this.$store.dispatch({
            type: 'financialstatement/getAll',
            data: this.pagerequest
        });
    }


    async mounted() {
        await this.loadData();
    }

    async created() {
    }
}
